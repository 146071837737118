import { useState, useEffect, useRef } from 'react';
import { Alert, Container, Row, Col, Card } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { Link, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';

import { JobAddress, formattedSalary } from '../../utils/JobUtils';
import { useMobileMediaQuery } from '../../hooks/responsiveMediaQueries';
import JobsControls from './JobsControls';
import PlaceholderJobCard from './PlaceholderJobCard';
import NonMobile from '../responsive/NonMobile';
import ViewJob from '../ViewJob';
import JobRewardSummary from './JobRewardSummary';
import '../../css/Jobs.css';

function Jobs({ organizationId, businessId, businessIds }) {
  const isMobile = useMobileMediaQuery();
  const [cookies] = useCookies(["job_search"]);
  const initialFormData = {
    what_search_text: "",
    where_search_text: "",
    filters: {
      job_type: "",
      job_type_id_eq: "",
      job_function: "",
      job_function_id_eq: "",
      job_industry: "",
      job_industry_id_eq: "",
      job_location_type: "",
      job_location_type_id_eq: "",
      job_experience_level: "",
      job_experience_level_id_eq: "",
      job_min_salary: "",
      job_min_salary_eq: ""
    }
  };

  const [formData, setFormData] = useState({ ...initialFormData, ...cookies.job_search });
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isLoadingInitialJobs, setIsLoadingInitialJobs] = useState(true);
  const [isLoadingJobs, setIsLoadingJobs] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [jobId, setJobId] = useState(searchParams.get("job_id"));
  const frame = searchParams.get('frame');
  const [currentPage, setCurrentPage] = useState(1);
  const [jobsCount, setJobsCount] = useState(0);
  const [triggerFetchJobs, setTriggerFetchJobs] = useState(false);
  const jobsFeedContainerRef = useRef(null);

  useEffect(() => {
    fetchJobs({});
  }, []);

  useEffect(() => {
    let shouldHighlightJob = !isLoadingInitialJobs && !isMobile;
    if (shouldHighlightJob) {
      selectJobToHighlight();
    }

  }, [jobId, isLoadingInitialJobs]);

  useEffect(() => {
    if (triggerFetchJobs && !isLoadingJobs) {
      let loadingMore = currentPage === 1 ? false : true;
      fetchJobs({ loadingMore: loadingMore });
    }

    setTriggerFetchJobs(false);
  }, [triggerFetchJobs]);

  function fetchJobs({ loadingMore = false }) {
    setIsLoadingJobs(true);
    let finalFormData = { ...formData, ...{ page: currentPage } };

    // Presence of businessId means an employer's job feed was requested, so business_id filter should always be included and cannot be cleared.
    if (businessId || businessIds) {
      finalFormData = { ...finalFormData, filters: { ...finalFormData.filters, business_id_in: businessId || businessIds }};
    }

    axios.get("/api/v1/jobs", { params: finalFormData }).then(
      (response) => {
        setJobsCount(parseInt(response.headers.total));
        loadingMore ? setJobs(jobs.concat(response.data)) : setJobs(response.data);

        setIsLoadingJobs(false);
        setIsLoadingInitialJobs(false);
      },
      () => {
        setIsLoadingJobs(false);
        setIsLoadingInitialJobs(false);
      }
    );
  }

  function selectJobToHighlight() {
    let jobIds = jobs.map(({ id }) => id);
    let selectedJobIsStale = selectedJob === null || selectedJob.id !== jobId;

    if (jobId && jobIds.includes(parseInt(jobId)) && selectedJobIsStale) {
      fetchSelectedJob(jobId);
    } else if (jobIds.length > 0) {
      const params = { job_id: jobIds[0] };
      if (frame !== null)
        params.frame = frame;

      setSearchParams(params);
      setJobId(jobIds[0]);
    }
  };

  function fetchSelectedJob(jobId) {
    axios.get(`/api/v1/jobs/${jobId}`)
      .then(
        (response) => {
          setSelectedJob(response.data);
        },
        () => {
          setSelectedJob(null);
        }
      );
  }

  function fetchFirstPageOfJobs() {
    setJobsCount(0);
    setJobs([]);
    setCurrentPage(1);
    setIsLoadingInitialJobs(true);
    setTriggerFetchJobs(true);
  }

  function fetchNextPageOfJobs() {
    setCurrentPage(currentPage + 1);
    setTriggerFetchJobs(true);
  }

  function noMoreJobsMessage() {
    return (
      <Alert className="text-center" variant={'secondary'}>
        <div>There are no more jobs that match your search.</div>
        {jobsCount > 4 ? <Alert.Link href="#" onClick={scrollJobsToTop}>Back to top</Alert.Link> : null}
      </Alert>
    )
  }

  function shouldLoadMoreJobs() {
    if (isLoadingJobs) {
      return false;
    } else {
      return (jobsCount === 0 || jobs.length < jobsCount);
    }
  }

  function scrollJobsToTop() {
    isMobile ? jobsFeedContainerRef.current.scroll({ top: 0, behavior: "smooth" }) : window.scrollTo(0, 0);
  }

  function getJobLink({job}) {
    const searchParams = new URLSearchParams();
    let jobLink = '';

    if (isMobile) {
      if (organizationId) {
        jobLink = `/employers/${job.business?.id}/jobs/${job.id}`;
      }
      else if (businessId) {
        jobLink = `/employers/${businessId}/jobs/${job.id}`;
      } else {
        jobLink = `/jobs/${job.id}`;
      }
    }
    else {
      searchParams.append('job_id', job.id);
    }

    frame !== null && searchParams.append('frame', frame);
    jobLink = `${jobLink}${searchParams.toString().length > 0 ? `?${searchParams.toString()}` : ''}`;

    return jobLink;
  }

  return (
    <Container>
      <JobsControls formDataContext={{ formData: formData, setFormData: setFormData }} filterJobs={fetchFirstPageOfJobs} ></JobsControls>

      <small><strong>{jobsCount}</strong>{jobsCount === 1 ? ' job' : ' jobs'}</small>

      {isLoadingJobs || jobs.length > 0 ?
        <Row className="mt-2">
          <Col sm={12} md={4} id="jobs-feed-container" className={"jobs-feed-container" + (isMobile && " overflow-auto")} ref={jobsFeedContainerRef}>
            <Row className="mt-2">
              <InfiniteScroll scrollableTarget={isMobile && "jobs-feed-container"} scrollThreshold={0.8} dataLength={jobs.length} next={fetchNextPageOfJobs} hasMore={shouldLoadMoreJobs()} loader={<PlaceholderJobCard />} endMessage={noMoreJobsMessage()}>
                {jobs.map(job => (
                  <Link key={"jobs-"+job.id} to={getJobLink({job})} className="link-effect" onClick={() => {!isMobile && setJobId(job.id)}}>
                    <Card className={`job-card mb-2 ${job.id === selectedJob?.id && "job-active"}`}>
                      <Card.Body>
                        <Card.Title>{job?.title}</Card.Title>
                        <Card.Text className="d-flex">
                          {job?.business?.logo && <img className="business-logo me-2" src={job.business.logo} alt="business-logo" />}
                          {JobAddress({job: job})}
                        </Card.Text>
                        <Card.Text>
                          <span className="ps-2 fw-bold">{job?.job_type?.name} - {job?.job_experience_level?.name}</span>
                        </Card.Text>
                        <Card.Text className="mb-1">
                          <span className="p-2 mb-2 fw-bold">{formattedSalary(job)}</span>
                        </Card.Text>
                      </Card.Body>
                      <Card.Footer className="d-flex justify-content-between flex-wrap-reverse">
                        <span className="text-muted mw-text-small me-2">Posted {job.posted_at} ago</span>
                        <JobRewardSummary reward={job.reward} />
                      </Card.Footer>
                    </Card>
                  </Link>
                ))}
              </InfiniteScroll>
            </Row>
          </Col>
          <NonMobile>
            <Col md={8} className="selected-job-container overflow-auto">
              {selectedJob && <ViewJob job={selectedJob} organizationId={organizationId} businessId={businessId} context={"search"} />}
            </Col>
          </NonMobile>
        </Row>
        :
        <Row className="justify-content-center">
          <Col xs={8} md={4} className="mt-4">
            <Alert className="text-center" variant={'secondary'}>
              <div>We are sorry.</div>
              <div>No jobs match your search.</div>
            </Alert>
          </Col>
        </Row>
      }
    </Container>
  );
}

export default Jobs;